import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "select", "client" ]

  connect() {
    new TomSelect(this.selectTarget ,{
      plugins: ['dropdown_input', 'input_autogrow']
    });

    new TomSelect(this.clientTarget ,{
      plugins: ['dropdown_input', 'input_autogrow', 'remove_button']
    });
  }
}
